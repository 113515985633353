import React, { FC } from 'react'
import { Form as ANTDForm } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { FormItemProps, FormProps } from 'antd/lib/form'
import { FormStyle } from 'src/@legacy/@core/style/styled/antd/From.style'

const Form: FC<FormProps> & { Item: FC<FormItemProps>; List: typeof ANTDForm.List } = (props) => (
  <>
    <style jsx global>
      {FormStyle}
    </style>
    <ANTDForm {...props} />
  </>
)
Form.Item = ANTDForm.Item
Form.List = ANTDForm.List
export { Form, useForm }
